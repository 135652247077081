<template>
  <div class="page_container">
    <div class="page_head">
      <div class="page_head-title">GENERAL SETTINGS</div>
    </div>
    <div class="page_content">
      <div class="page_title">Settings</div>
      <div class="page_url-container">

        <div class="page_url-item">
          <div class="url_link">Rating URL:<a class="url_link-value" :href="urlData.rating_url" target="_blank">{{ urlData.rating_url }}</a></div>
          <div class="url_input">
            <b-form-input v-model="urlData.rating_url"></b-form-input>
          </div>
          <div class="url_btn-container">
            <b-button class="url_btn" @click="changeUrl('rating')">Change Url</b-button><span class="url_link-value" v-if="success_msg && message_status == 'rating'">{{success_msg}}</span>
          </div>
        </div>
        <div class="page_url-item">
          <div class="url_link">Middle School URL:<a class="url_link-value" :href="urlData.middle_school_url" target="_blank">{{ urlData.middle_school_url }}</a></div>
          <div class="url_input">
            <b-form-input v-model="urlData.middle_school_url"></b-form-input>
          </div>
          <div class="url_btn-container">
            <b-button class="url_btn" @click="changeUrl('middle')">Change Url</b-button><span class="url_link-value" v-if="success_msg && message_status == 'middle'">{{success_msg}}</span>
          </div>
        </div>
        <div class="page_url-item">
          <div class="url_link">High School URL:<a class="url_link-value" :href="urlData.high_school_url" target="_blank">{{ urlData.high_school_url }}</a></div>
          <div class="url_input">
            <b-form-input v-model="urlData.high_school_url"></b-form-input>
          </div>
          <div class="url_btn-container">
            <b-button class="url_btn" @click="changeUrl('high')">Change Url</b-button><span class="url_link-value" v-if="success_msg && message_status == 'high'">{{success_msg}}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "GeneralSettings",
  data() {
    return {
      urlData: {
        rating_url: null,
        middle_school_url: null,
        high_school_url: null,
        id: null
      },
      success_msg: null,
      message_status: null
    }
  },
  created() {
    this.getUrls()
  },
  methods: {
    ...mapActions(['getAllURLs', 'updateURL']),
    getUrls() {
      this.getAllURLs()
          .then(res => {
            if(res.data !== []) {
              this.urlData.rating_url = res.data.rating_url
              this.urlData.middle_school_url = res.data.middle_school_url
              this.urlData.high_school_url = res.data.high_school_url
              if(res.data.id) {
                this.urlData.id = res.data.id
              }
            }
          })
    },
    changeUrl(item) {
      this.message_status = item
      this.updateURL(this.urlData)
      .then(res => {
        if(res) {
          this.getUrls()
          this.success_msg = res.data.message
          setTimeout(() => {
            this.success_msg = null
            this.message_status = null
          }, 5000)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  &_container {
    width: 100%;
  }

  &_head {
    width: 100%;
    height: 73px;
    background: linear-gradient(180deg, #003761 0%, #00528C 79.64%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    position: sticky;
    top: 0;
    z-index: 3;

    &-title {
      color: #FFFFFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 80px;
  }

  &_title {
    color: #11334D;
    font-size: 38.059px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &_url {
    &-container {
      width: 70%;
      display: flex;
      flex-direction: column;

    }

    &-item {
      margin: 15px 0;
      max-width: 880px;
      width: 100%;
      height: 180px;
      border-radius: 12px;
      border: 1px solid #11334D;
      padding: 10px 50px 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & .url {
        &_link {
          color: #11334D;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding: 10px 0;
          &-value {
            color: #00A99D;
            margin-left: 20px;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

        }

        &_input {
          input {
            border-radius: 8px;
            border: 1px solid #11334D;
            height: 44px !important;
          }

        }

        &_btn {
          margin: 20px 0;
          width: 180px;
          height: 40px;
          border-radius: 12px;
          border: 1px solid #FFFFFF;
          background: #00A99D;
          color: #FFFFFF;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          &-container {

          }
        }
      }
    }
  }
}
</style>
